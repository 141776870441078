
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import FilterModel from "../components/filter-model";
import getUTC from "@/function/getUTC";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetWebLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/GetWebLogsResponse";
import WebLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/WebLogsItemResponse";
import GetWebLogRequest from "@/dataSource/api/logger-micro-service/services/models/request/web-getter-service/GetWebLogRequest";
import GetTopWebLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/GetTopWebLogsResponse";
import TopWebLogItem from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/TopWebLogItem";
import HttpMethodEnum from "@/dataSource/api/tirscript/logger-micro-service/enums/HttpMethodEnum";

@Options({
  name: "HttpLogsView",
  components: {
    TableView,
  },
})
export default class LogsView extends Vue {
  // @Prop({ default: new FilterModel() }) filter: FilterModel;

  enumMethod = HttpMethodEnum;
  innerFilter: GetWebLogRequest;
  items: WebLogsItemResponse[] | TopWebLogItem[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;
  filter: FilterModel = new FilterModel();
  //получить данные фильтра для страницы если они есть
 
  created() {
    if (this.filter && (this.filter.SkipPage || this.filter.SkipPage == 0)) {
      this.skipPage = this.filter.SkipPage;
    }
  }

  type(item: any) {
    switch (item.Level) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }

  onFilterChange() {
    this.getInLocalStor();
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    this.refresh();
  }

    //получить данные фильтра для страницы если они есть
    getInLocalStor() {
    if (localStorage.logsViewFilter) {
      this.filter = JSON.parse(localStorage.logsViewFilter);
      const t = 0// new Date().getTimezoneOffset();
      this.filter.DateFrom = this.addMinutes(this.filter.DateFrom, t);
      this.filter.DateTo = this.addMinutes(this.filter.DateTo, t);
      return
    }
    // this.saveInLocalStor()
  }
  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }
    this.getInLocalStor() 
    // this.skipPage = this.filter.SkipPage;
    let response: GetWebLogsResponse | GetTopWebLogsResponse = null;
    this.isLoading = true;
    this.innerFilter = {
      OnlyActiveRequests: null,
      OnlyOpenedConnections: null,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      RequestId: this.filter.RequestId,
      Ip: this.filter.Ip,
      SessionToken: this.filter.SessionToken,
      Url: this.filter.Url,
      UserId: this.filter.UserId,

      LogLevel: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: { Skip: this.skipPage, Take: this.takePage },
    };

    let tmpFilter = getUTC(this.innerFilter);
    try {
      if (this.$route.params.top == "isTopFatReq") {
        response = await this.$api.TopWebLogsService.getTopFatRequestsAsync(tmpFilter);
      }
      if (this.$route.params.top == "isTopFatRes") {
        response = await this.$api.TopWebLogsService.getTopFatResponsesAsync(tmpFilter);
      }
      if (this.$route.params.top == "isTopLongReq") {
        response = await this.$api.TopWebLogsService.getTopLongRequestsAsync(tmpFilter);
      }
      if (!this.$route.params.top) {
        response = await this.$api.WebLogGetterService.getLogsAsync(tmpFilter);
      }
    } catch (error) {
      console.log(`error:`, error);
      this.$notification.error(error.ErrorMessage);
      response = new GetWebLogsResponse();
      response.Items = []
      response.Page = new PageResponse()
      this.isLoading = false;
    }
    this.isLoading = false;

    this.pageResponse = response.Page;

    this.items = response.Items;

    if (this.$refs.pagination) {
      (this.$refs.pagination as any).currentPage = this.skipPage / this.takePage + 1;
    }
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {

    let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    if (this.skipPage === skip) {
      return;
    }

    this.skipPage = skip;
    // this.updateQuery();
    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  updateQuery() {
    this.$emit("updateQuery", this.skipPage);
  }
}
